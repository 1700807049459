import React from 'react'
import { Link } from 'gatsby'
import { ThemeProvider, ThemeToggle } from '../components/Theme'
import { Helmet } from 'react-helmet'

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider>
      <Helmet>
        {/* Cloudflare web analytics */}
        <script
          defer
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon='{"token": "69838a0b428b43348e1216649ca4f555"}'
        ></script>
        <script
          data-goatcounter="https://blesswinsamuel.goatcounter.com/count"
          async
          src="//gc.zgo.at/count.js"
        ></script>
      </Helmet>
      <LayoutChild>{children}</LayoutChild>
    </ThemeProvider>
  )
}

const LayoutChild: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      <header className={'w-full py-8'}>
        <div className="max-w-prose mx-auto px-4 flex items-center justify-between">
          <h1 className="text-xl font-bold">
            <Link
              to="/"
              className={
                'p-1 focus:outline-none focus:ring focus:ring-gray-400 transition bg-black text-white dark:text-black dark:bg-white'
              }
            >
              blesswinsamuel.com
            </Link>
          </h1>
          <ThemeToggle />
        </div>
      </header>
      <main className="max-w-prose mx-auto px-4">{children}</main>
      {/* <footer>
        © {new Date().getFullYear()}
      </footer> */}
    </div>
  )
}

export default Layout
