import React from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import Button from './Button'

interface UseThemeProps {
  setTheme: (theme: string) => void
  toggleTheme: () => void
  theme?: string
}

const ThemeContext = createContext<UseThemeProps>({
  setTheme: _ => {},
  toggleTheme: () => {},
})

export function useTheme() {
  return useContext(ThemeContext)
}

export interface ThemeProviderProps {
  storageKey?: string
}

const getPreferredColorScheme = () => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches === true) {
      return 'dark'
    } else {
      return 'light'
    }
  }
  return 'light'
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  storageKey = 'theme',
  children,
}) => {
  const [theme, setTheme] = useState(
    () => getLsTheme(storageKey) || getPreferredColorScheme()
  )

  useEffect(() => {
    if (getLsTheme(storageKey)) {
      return
    }
    if (window.matchMedia) {
      var colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)')
      const changeTheme = (e: MediaQueryListEvent) => {
        setTheme(getPreferredColorScheme())
      }
      colorSchemeQuery.addEventListener('change', changeTheme)
      return () => {
        colorSchemeQuery.removeEventListener('change', changeTheme)
      }
    }
  }, [storageKey])

  // update local storage
  useEffect(() => {
    setLsTheme(storageKey, theme)
  }, [storageKey, theme])

  // change background and text color
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark')
      return () => {
        document.documentElement.classList.remove('dark')
      }
    }
  }, [theme])

  const toggleTheme = useCallback(() => {
    setTheme(theme => ({ light: 'dark', dark: 'light' }[theme]))
  }, [])

  return (
    <ThemeContext.Provider
      value={{
        setTheme: setTheme,
        toggleTheme: toggleTheme,
        theme: theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export function ThemeToggle() {
  const { toggleTheme } = useTheme()

  // https://github.com/leerob/leerob.io/blob/3f0388d9a9656b88bbcaf2383e3709bad36dafb6/components/Container.js#L23-L46
  return (
    <Button
      className="h-7 w-7 p-1 flex items-center justify-center"
      onClick={toggleTheme}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke="currentColor"
        className="h-full w-full"
      >
        {/* Sun */}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          className="opacity-0 dark:opacity-100 transition-opacity"
          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
        />
        {/* Moon */}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          className="opacity-100 dark:opacity-0 transition-opacity"
          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
      </svg>
    </Button>
  )
}

const getLsTheme = (key: string) => {
  if (typeof window === 'undefined') return undefined
  let theme: string | null
  try {
    theme = localStorage.getItem(key) || undefined
  } catch (e) {
    // Unsupported
  }
  return theme
}

const setLsTheme = (key: string, theme: string | null) => {
  if (typeof window === 'undefined') return undefined
  try {
    localStorage.setItem(key, theme)
  } catch (e) {
    // Unsupported
  }
}
