import React from 'react'

type ButtonProps = {
  component: any
  rounded: boolean
  className: string
} & any

export default function Button({
  component: Component = 'button',
  rounded = false,
  className,
  ...props
}: ButtonProps) {
  const classes = []
  classes.push(
    'block no-underline text-center border-2 transition duration-200 ease'
  )
  classes.push(
    'focus:outline-none focus:ring focus:ring-gray-400 focus:border-gray-300'
  )
  // light
  classes.push(
    'text-gray-800 border-gray-600 hover:bg-gray-800 hover:text-white'
  )
  // dark
  classes.push(
    'dark:text-gray-200 dark:border-gray-400 dark:hover:bg-gray-200 dark:hover:text-black'
  )
  if (rounded) {
    classes.push('rounded-full')
  } else {
    classes.push('rounded')
  }
  classes.push(className)
  return <Component className={classes.filter(x => x).join(' ')} {...props} />
}
